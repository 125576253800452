var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-document-package",
        title: _vm.FormMSG(79, "Document selection"),
        "header-class": "header-class-modal-doc-package",
        size: "lg",
        "hide-header-close": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "ok-title": _vm.FormMSG(36, "Add document"),
        "modal-class": "mui-animation",
        fade: false,
      },
      on: { show: _vm.handleShowModalDocumentPackage },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex flex-row justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "3", md: "3", lg: "3", xl: "3" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "light",
                            disabled: _vm.isLoading,
                            block: "",
                          },
                          on: { click: _vm.emitEventClose },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(35, "Cancel")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "3", md: "3", lg: "3", xl: "3" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            disabled: _vm.cantAddDocument || _vm.isLoading,
                            block: "",
                          },
                          on: { click: _vm.handleSubmit },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(2, "Save")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "div",
              { staticClass: "container-field form" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "b-button-group",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: `${
                                    !_vm.fromDocument ? "outline-" : ""
                                  }primary`,
                                },
                                on: { click: _vm.toggleFromDocument },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(25, "From document list")
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: `${
                                    _vm.fromDocument ? "outline-" : ""
                                  }primary`,
                                },
                                on: { click: _vm.toggleFromDocument },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(26, "Create new document")
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.fromDocument
                  ? _c(
                      "b-row",
                      { staticClass: "mt-20" },
                      [
                        _c(
                          "b-col",
                          {
                            attrs: {
                              sm: `${!_vm.showEyeFromDocument ? 12 : 10}`,
                              md: `${!_vm.showEyeFromDocument ? 12 : 10}`,
                              lg: `${!_vm.showEyeFromDocument ? 12 : 10}`,
                              xl: `${!_vm.showEyeFromDocument ? 12 : 10}`,
                            },
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.FormMSG(27, "Document selection"),
                                },
                              },
                              [
                                _c("v-select", {
                                  attrs: {
                                    options: _vm.documentList,
                                    label: "name",
                                    placeholder: _vm.FormMSG(
                                      28,
                                      "Select a document"
                                    ),
                                  },
                                  model: {
                                    value: _vm.documentSelected,
                                    callback: function ($$v) {
                                      _vm.documentSelected = $$v
                                    },
                                    expression: "documentSelected",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.showEyeFromDocument
                          ? _c(
                              "b-col",
                              {
                                staticClass: "w-100 px-0",
                                class: {
                                  "pt-35": _vm.$screen.width > 576,
                                },
                                attrs: {
                                  sm: `${!_vm.showEyeFromDocument ? 0 : 2}`,
                                  md: `${!_vm.showEyeFromDocument ? 0 : 2}`,
                                  lg: `${!_vm.showEyeFromDocument ? 0 : 2}`,
                                  xl: `${!_vm.showEyeFromDocument ? 0 : 2}`,
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-transparent text-color-rhapsody-in-blue",
                                    attrs: { block: "" },
                                    on: {
                                      click:
                                        _vm.handlePreviewDocForDocumentSelected,
                                    },
                                  },
                                  [
                                    _c(_vm.getLucideIcon("Eye"), {
                                      tag: "component",
                                      attrs: { size: 18 },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.FormMSG(29, "View document")
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "mt-20" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-alert",
                                  {
                                    attrs: {
                                      variant: "danger",
                                      show: _vm.uploadError,
                                      dismissible: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            168,
                                            "Submit docfile failure !!"
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "6", lg: "6", xl: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: _vm.FormMSG(30, "Name") } },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        placeholder: _vm.FormMSG(
                                          31,
                                          "Trainee contract"
                                        ),
                                      },
                                      model: {
                                        value: _vm.newDoc.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.newDoc, "name", $$v)
                                        },
                                        expression: "newDoc.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "6", lg: "6", xl: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: _vm.FormMSG(32, "Type") } },
                                  [
                                    _c("b-form-select", {
                                      attrs: { options: _vm.types },
                                      on: {
                                        change: _vm.handleChangeNewDocType,
                                      },
                                      model: {
                                        value: _vm.newDoc.type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.newDoc, "type", $$v)
                                        },
                                        expression: "newDoc.type",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "6", lg: "6", xl: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: _vm.FormMSG(1, "File") } },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-file", {
                                          ref: "file-input-doc-package",
                                          staticClass: "mb-2",
                                          attrs: {
                                            accept: ".jpg,.png,.pdf,.doc,.docx",
                                            placeholder: _vm.FormMSG(
                                              34,
                                              "Choose a file or drop it here"
                                            ),
                                            "browse-text": _vm.FormMSG(
                                              113,
                                              "Browse"
                                            ),
                                            "drop-placeholder": _vm.FormMSG(
                                              125,
                                              "Drop file here ..."
                                            ),
                                          },
                                          on: {
                                            change:
                                              _vm.handleChangeFileToUploaded,
                                          },
                                          model: {
                                            value: _vm.fileToUploaded,
                                            callback: function ($$v) {
                                              _vm.fileToUploaded = $$v
                                            },
                                            expression: "fileToUploaded",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "pt-28",
                                attrs: {
                                  sm: `${
                                    _vm.newDoc.fileName &&
                                    _vm.newDoc.fileName.length > 0
                                      ? 10
                                      : 12
                                  }`,
                                  md: `${
                                    _vm.newDoc.fileName &&
                                    _vm.newDoc.fileName.length > 0
                                      ? 4
                                      : 6
                                  }`,
                                  lg: `${
                                    _vm.newDoc.fileName &&
                                    _vm.newDoc.fileName.length > 0
                                      ? 4
                                      : 6
                                  }`,
                                  xl: `${
                                    _vm.newDoc.fileName &&
                                    _vm.newDoc.fileName.length > 0
                                      ? 4
                                      : 6
                                  }`,
                                },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "" } },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        value: _vm.newDoc.fileName,
                                        placeholder: _vm.FormMSG(
                                          42,
                                          "File name"
                                        ),
                                        disabled: "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.newDoc.fileName &&
                            _vm.newDoc.fileName.length > 0
                              ? _c(
                                  "b-col",
                                  {
                                    class: {
                                      "pt-28": _vm.$screen.width > 576,
                                    },
                                    attrs: {
                                      sm: "2",
                                      md: "2",
                                      lg: "2",
                                      xl: "2",
                                    },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        class: {
                                          "btn-transparent text-color-burning-tomato":
                                            _vm.$screen.width > 576,
                                          "btn btn-danger mr-2":
                                            _vm.$screen.width <= 576,
                                        },
                                        on: { click: _vm.handleClickTrashFile },
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Trash2"), {
                                          tag: "component",
                                          attrs: {
                                            size: 22,
                                            "stroke-width": 1.5,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "button",
                                      {
                                        class: {
                                          "btn-transparent text-color-rhapsody-in-blue":
                                            _vm.$screen.width > 576,
                                          "btn btn-primary":
                                            _vm.$screen.width <= 576,
                                        },
                                        on: {
                                          click:
                                            _vm.handlePreviewDocForFileUploaded,
                                        },
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Eye"), {
                                          tag: "component",
                                          attrs: {
                                            size: 22,
                                            "stroke-width": 1.5,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "fw-700 fs-20" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(253, "Options")) +
                              "\n\t\t\t\t\t"
                          ),
                        ]),
                        _c(
                          "b-row",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: {
                                      size: "lg",
                                      value: true,
                                      "unchecked-value": false,
                                    },
                                    model: {
                                      value: _vm.newDoc.toSign,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.newDoc, "toSign", $$v)
                                      },
                                      expression: "newDoc.toSign",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(147, "To sign")) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: {
                                      size: "lg",
                                      value: true,
                                      "unchecked-value": false,
                                      disabled: !_vm.isPdfFile,
                                    },
                                    model: {
                                      value: _vm.newDoc.useWatermark,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newDoc,
                                          "useWatermark",
                                          $$v
                                        )
                                      },
                                      expression: "newDoc.useWatermark",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(265, "Add watermark")
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-collapse",
                          { attrs: { visible: _vm.newDoc.useWatermark } },
                          [
                            _vm.newDoc.useWatermark
                              ? _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                267,
                                                "Watermark text"
                                              ),
                                            },
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  267,
                                                  "Watermark text"
                                                ),
                                              },
                                              model: {
                                                value: _vm.newDoc.watermarkText,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newDoc,
                                                    "watermarkText",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newDoc.watermarkText",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "pt-28",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            attrs: {
                                              size: "lg",
                                              value: true,
                                              "unchecked-value": false,
                                              disabled: !_vm.isPdfFile,
                                            },
                                            model: {
                                              value:
                                                _vm.newDoc.watermarkWithUser,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newDoc,
                                                  "watermarkWithUser",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "newDoc.watermarkWithUser",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    266,
                                                    "With user name"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.newDoc.useWatermark
                              ? _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: _vm.FormMSG(
                                                268,
                                                "Watermark image"
                                              ),
                                            },
                                          },
                                          [
                                            _c("b-form-file", {
                                              ref: "file-watermark-doc-package",
                                              staticClass: "mb-2",
                                              attrs: {
                                                accept: ".jpg,.png",
                                                placeholder: _vm.FormMSG(
                                                  34,
                                                  "Choose a file or drop it here"
                                                ),
                                                "browse-text": _vm.FormMSG(
                                                  113,
                                                  "Browse"
                                                ),
                                                "drop-placeholder": _vm.FormMSG(
                                                  125,
                                                  "Drop file here ..."
                                                ),
                                              },
                                              on: {
                                                change:
                                                  _vm.handleChangeWatermarkToUploaded,
                                              },
                                              model: {
                                                value:
                                                  _vm.fileWatermarkToUploaded,
                                                callback: function ($$v) {
                                                  _vm.fileWatermarkToUploaded =
                                                    $$v
                                                },
                                                expression:
                                                  "fileWatermarkToUploaded",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "pt-28",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c(
                                          "b-form-group",
                                          { attrs: { label: "" } },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                placeholder: _vm.FormMSG(
                                                  42,
                                                  "File name"
                                                ),
                                                disabled: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.newDoc
                                                    .watermarkPictureFileName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newDoc,
                                                    "watermarkPictureFileName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newDoc.watermarkPictureFileName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "b-collapse",
                          { attrs: { visible: _vm.showPasswordField } },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            278,
                                            "Pdf user password"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { staticClass: "mb-4" },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: !_vm.showTextPassword
                                                  ? "password"
                                                  : "text",
                                                placeholder: _vm.FormMSG(
                                                  214,
                                                  "If protected, enter document user password ."
                                                ),
                                              },
                                              model: {
                                                value: _vm.newDoc.userPassword,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newDoc,
                                                    "userPassword",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newDoc.userPassword",
                                              },
                                            }),
                                            _c("b-input-group-append", [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn-show-password",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showTextPassword =
                                                        !_vm.showTextPassword
                                                    },
                                                  },
                                                },
                                                [
                                                  !_vm.showTextPassword
                                                    ? _c(
                                                        _vm.getLucideIcon(
                                                          "Eye"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color: "#9c9d9e",
                                                            size: 16,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  _vm.showTextPassword
                                                    ? _c(
                                                        _vm.getLucideIcon(
                                                          "EyeOff"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color: "#9c9d9e",
                                                            size: 16,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: _vm.FormMSG(
                                            279,
                                            "Pdf owner password"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { staticClass: "mb-4" },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: !_vm.showTextConfirmPassword
                                                  ? "password"
                                                  : "text",
                                                placeholder: _vm.FormMSG(
                                                  216,
                                                  "Enter document owner password."
                                                ),
                                              },
                                              model: {
                                                value: _vm.newDoc.ownerPassword,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newDoc,
                                                    "ownerPassword",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "newDoc.ownerPassword",
                                              },
                                            }),
                                            _c("b-input-group-append", [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn-show-password",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showTextConfirmPassword =
                                                        !_vm.showTextConfirmPassword
                                                    },
                                                  },
                                                },
                                                [
                                                  !_vm.showTextConfirmPassword
                                                    ? _c(
                                                        _vm.getLucideIcon(
                                                          "Eye"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color: "#9c9d9e",
                                                            size: 16,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  _vm.showTextConfirmPassword
                                                    ? _c(
                                                        _vm.getLucideIcon(
                                                          "EyeOff"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color: "#9c9d9e",
                                                            size: 16,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            _c("b-col", [
                              _c("p", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(
                                        127,
                                        "Note: The document created here will be added on the document list, under the menu Production / Document, and will also be added in the document package."
                                      )
                                    ) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }