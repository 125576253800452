<template>
	<b-modal
		id="modal-document-package"
		v-model="isOpen"
		:title="FormMSG(79, 'Document selection')"
		header-class="header-class-modal-doc-package"
		size="lg"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		:ok-title="FormMSG(36, 'Add document')"
		@show="handleShowModalDocumentPackage"
		modal-class="mui-animation"
		:fade="false"
	>
		<b-row>
			<b-col>
				<div class="container-field form">
					<b-row>
						<b-col class="text-center">
							<b-button-group>
								<b-button :variant="`${!fromDocument ? 'outline-' : ''}primary`" @click="toggleFromDocument">
									{{ FormMSG(25, 'From document list') }}
								</b-button>
								<b-button :variant="`${fromDocument ? 'outline-' : ''}primary`" @click="toggleFromDocument">
									{{ FormMSG(26, 'Create new document') }}
								</b-button>
							</b-button-group>
						</b-col>
					</b-row>
					<b-row v-if="fromDocument" class="mt-20">
						<b-col
							:sm="`${!showEyeFromDocument ? 12 : 10}`"
							:md="`${!showEyeFromDocument ? 12 : 10}`"
							:lg="`${!showEyeFromDocument ? 12 : 10}`"
							:xl="`${!showEyeFromDocument ? 12 : 10}`"
						>
							<b-form-group :label="FormMSG(27, 'Document selection')">
								<v-select v-model="documentSelected" :options="documentList" label="name" :placeholder="FormMSG(28, 'Select a document')" />
							</b-form-group>
						</b-col>
						<b-col
							:sm="`${!showEyeFromDocument ? 0 : 2}`"
							:md="`${!showEyeFromDocument ? 0 : 2}`"
							:lg="`${!showEyeFromDocument ? 0 : 2}`"
							:xl="`${!showEyeFromDocument ? 0 : 2}`"
							class="w-100 px-0"
							:class="{
								'pt-35': $screen.width > 576
							}"
							v-if="showEyeFromDocument"
						>
							<button class="btn-transparent text-color-rhapsody-in-blue" @click="handlePreviewDocForDocumentSelected" block>
								<!-- <eye :size="18" /> {{ FormMSG(29, 'View document') }} -->
								<component :is="getLucideIcon('Eye')" :size="18" />&nbsp;{{ FormMSG(29, 'View document') }}
							</button>
						</b-col>
					</b-row>
					<div v-else class="mt-20">
						<b-row>
							<b-col>
								<b-alert variant="danger" :show="uploadError" dismissible>
									{{ FormMSG(168, 'Submit docfile failure !!') }}
								</b-alert>
							</b-col>
						</b-row>
						<b-row>
							<b-col sm="12" md="6" lg="6" xl="6">
								<b-form-group :label="FormMSG(30, 'Name')">
									<b-form-input v-model="newDoc.name" :placeholder="FormMSG(31, 'Trainee contract')" />
								</b-form-group>
							</b-col>
							<b-col sm="12" md="6" lg="6" xl="6">
								<b-form-group :label="FormMSG(32, 'Type')">
									<b-form-select v-model="newDoc.type" :options="types" @change="handleChangeNewDocType" />
								</b-form-group>
							</b-col>
						</b-row>
						<b-row>
							<b-col sm="12" md="6" lg="6" xl="6">
								<b-form-group :label="FormMSG(1, 'File')">
									<b-input-group>
										<b-form-file
											v-model="fileToUploaded"
											ref="file-input-doc-package"
											class="mb-2"
											accept=".jpg,.png,.pdf,.doc,.docx"
											@change="handleChangeFileToUploaded"
											:placeholder="FormMSG(34, 'Choose a file or drop it here')"
											:browse-text="FormMSG(113, 'Browse')"
											:drop-placeholder="FormMSG(125, 'Drop file here ...')"
										/>
									</b-input-group>
								</b-form-group>
							</b-col>
							<b-col
								:sm="`${newDoc.fileName && newDoc.fileName.length > 0 ? 10 : 12}`"
								:md="`${newDoc.fileName && newDoc.fileName.length > 0 ? 4 : 6}`"
								:lg="`${newDoc.fileName && newDoc.fileName.length > 0 ? 4 : 6}`"
								:xl="`${newDoc.fileName && newDoc.fileName.length > 0 ? 4 : 6}`"
								class="pt-28"
							>
								<b-form-group label="">
									<b-form-input :value="newDoc.fileName" :placeholder="FormMSG(42, 'File name')" disabled />
								</b-form-group>
							</b-col>
							<b-col
								v-if="newDoc.fileName && newDoc.fileName.length > 0"
								sm="2"
								md="2"
								lg="2"
								xl="2"
								:class="{
									'pt-28': $screen.width > 576
								}"
							>
								<button
									:class="{
										'btn-transparent text-color-burning-tomato': $screen.width > 576,
										'btn btn-danger mr-2': $screen.width <= 576
									}"
									@click="handleClickTrashFile"
								>
									<component :is="getLucideIcon('Trash2')" :size="22" :stroke-width="1.5" />
								</button>
								<button
									:class="{
										'btn-transparent text-color-rhapsody-in-blue': $screen.width > 576,
										'btn btn-primary': $screen.width <= 576
									}"
									@click="handlePreviewDocForFileUploaded"
								>
									<component :is="getLucideIcon('Eye')" :size="22" :stroke-width="1.5" />
								</button>
							</b-col>
						</b-row>
						<p class="fw-700 fs-20">
							{{ FormMSG(253, 'Options') }}
						</p>
						<b-row class="mb-3">
							<b-col cols="6">
								<b-form-checkbox v-model="newDoc.toSign" size="lg" :value="true" :unchecked-value="false">
									{{ FormMSG(147, 'To sign') }}
								</b-form-checkbox>
							</b-col>
							<b-col cols="6">
								<b-form-checkbox v-model="newDoc.useWatermark" size="lg" :value="true" :unchecked-value="false" :disabled="!isPdfFile">
									{{ FormMSG(265, 'Add watermark') }}
								</b-form-checkbox>
							</b-col>
						</b-row>
						<b-collapse :visible="newDoc.useWatermark">
							<b-row v-if="newDoc.useWatermark">
								<b-col cols="6">
									<b-form-group :label="FormMSG(267, 'Watermark text')">
										<b-form-input v-model="newDoc.watermarkText" :placeholder="FormMSG(267, 'Watermark text')" />
									</b-form-group>
								</b-col>
								<b-col cols="6" class="pt-28">
									<b-form-checkbox v-model="newDoc.watermarkWithUser" size="lg" :value="true" :unchecked-value="false" :disabled="!isPdfFile">
										{{ FormMSG(266, 'With user name') }}
									</b-form-checkbox>
								</b-col>
							</b-row>
							<b-row v-if="newDoc.useWatermark">
								<b-col cols="6">
									<b-form-group :label="FormMSG(268, 'Watermark image')">
										<b-form-file
											v-model="fileWatermarkToUploaded"
											ref="file-watermark-doc-package"
											class="mb-2"
											accept=".jpg,.png"
											@change="handleChangeWatermarkToUploaded"
											:placeholder="FormMSG(34, 'Choose a file or drop it here')"
											:browse-text="FormMSG(113, 'Browse')"
											:drop-placeholder="FormMSG(125, 'Drop file here ...')"
										/>
									</b-form-group>
								</b-col>
								<b-col cols="6" class="pt-28">
									<b-form-group label="">
										<b-form-input v-model="newDoc.watermarkPictureFileName" :placeholder="FormMSG(42, 'File name')" disabled />
									</b-form-group>
								</b-col>
							</b-row>
						</b-collapse>
						<b-collapse :visible="showPasswordField">
							<b-row>
								<b-col cols="6">
									<b-form-group :label="FormMSG(278, 'Pdf user password')">
										<b-input-group class="mb-4">
											<b-form-input
												v-model="newDoc.userPassword"
												:type="!showTextPassword ? 'password' : 'text'"
												:placeholder="FormMSG(214, 'If protected, enter document user password .')"
											/>
											<b-input-group-append>
												<button class="btn-show-password" type="button" @click="showTextPassword = !showTextPassword">
													<component v-if="!showTextPassword" :is="getLucideIcon('Eye')" color="#9c9d9e" :size="16" />
													<component :is="getLucideIcon('EyeOff')" v-if="showTextPassword" color="#9c9d9e" :size="16" />
												</button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
								<b-col cols="6">
									<b-form-group :label="FormMSG(279, 'Pdf owner password')">
										<b-input-group class="mb-4">
											<b-form-input
												v-model="newDoc.ownerPassword"
												:type="!showTextConfirmPassword ? 'password' : 'text'"
												:placeholder="FormMSG(216, 'Enter document owner password.')"
											/>
											<b-input-group-append>
												<button class="btn-show-password" type="button" @click="showTextConfirmPassword = !showTextConfirmPassword">
													<component v-if="!showTextConfirmPassword" :is="getLucideIcon('Eye')" color="#9c9d9e" :size="16" />
													<component :is="getLucideIcon('EyeOff')" v-if="showTextConfirmPassword" color="#9c9d9e" :size="16" />
												</button>
											</b-input-group-append>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>
						</b-collapse>

						<b-row>
							<b-col>
								<p>
									{{
										FormMSG(
											127,
											'Note: The document created here will be added on the document list, under the menu Production / Document, and will also be added in the document package.'
										)
									}}
								</p>
							</b-col>
						</b-row>
					</div>
				</div>
			</b-col>
		</b-row>
		<template #modal-footer>
			<div class="w-100 d-flex flex-row justify-content-end align-items-center">
				<b-col sm="3" md="3" lg="3" xl="3">
					<b-button variant="light" :disabled="isLoading" block @click="emitEventClose">
						{{ FormMSG(35, 'Cancel') }}
					</b-button>
				</b-col>
				<b-col sm="3" md="3" lg="3" xl="3">
					<b-button variant="primary" :disabled="cantAddDocument || isLoading" block @click="handleSubmit">
						{{ FormMSG(2, 'Save') }}
					</b-button>
				</b-col>
			</div>
		</template>
	</b-modal>
</template>

<script>
// import { Eye, Search, Trash2 } from 'lucide-vue'
import { getDocumentList, newDocument, updateDocument } from '@/modules/document-package/cruds/document.crud';
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';
import _ from 'lodash';
import globalMixin from '@/mixins/global.mixin';
import { getFileExtension } from '@/shared/helpers';
import * as icons from 'lucide-vue';

const axios = require('axios').create();

export default {
	name: 'ModalDocumentPackage',

	components: {
		// Eye,
		// Search,
		// Trash2,
	},

	mixins: [languageMessages, globalMixin],

	props: {
		open: { type: Boolean, default: false },
		documentPackage: { type: Object, required: true }
	},

	data() {
		return {
			fromDocument: true,
			documentList: [],
			typeSelected: 9,
			newDoc: {
				name: '',
				type: 8,
				fileName: '',
				xid: '',
				toSign: false,
				useWatermark: false,
				watermarkText: '',
				watermarkWithUser: false,
				userPassword: '',
				watermarkPictureFileName: '',
				ownerPassword: '',
				watermarkPictureXid: ''
			},
			uploadError: false,
			isLoading: false,
			fileToUploaded: null,
			fileWatermarkToUploaded: null,
			eventFile: null,
			eventWatermarkFile: null,
			wartermarkImageUrl: null,
			documentSelected: null,
			imageUrl: null,

			documentParentId: 0,
			showTextConfirmPassword: false,
			showTextPassword: false
		};
	},

	computed: {
		showPasswordField() {
			if (this.isPdfFile) {
				if (this.newDoc.useWatermark || this.newDoc.toSign) {
					return true;
				}
			}
			return false;
		},
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		types() {
			let menues = this.FormMenu(1011);
			menues = menues.filter((m) => m.value !== 11);
			return menues;
		},
		cantAddDocument() {
			if (!this.fromDocument) {
				if (this.newDoc.name !== '' && this.newDoc.fileName !== '') {
					return false;
				}
				return true;
			}

			if (!_.isNil(this.documentSelected)) {
				return false;
			}
			return true;
		},
		showEyeFromDocument() {
			if (!_.isNil(this.documentSelected) && this.documentSelected.xid !== '') {
				return true;
			}
			return false;
		},
		isPdfFile() {
			// check if file is a pdf
			if (this.newDoc.fileName.length == 0) {
				return false;
			}
			const fileName = this.newDoc.fileName;
			const nameLen = fileName.length;
			const lastDotPos = fileName.lastIndexOf('.');
			let fileNameSub = false;
			if (lastDotPos === -1) {
				return false;
			} else {
				//Remove +1 if you want the "." left too
				fileNameSub = fileName.substr(lastDotPos + 1, nameLen).toLowerCase();
				if (fileNameSub == 'pdf') {
					return true;
				}
			}
			return false;
		}
	},

	methods: {
		handlePreviewDocForFileUploaded() {
			const list = [
				{
					...this.newDoc,
					xid: Date.now().toString(),
					src: this.imageUrl,
					thumb: '',
					ext: this.newDoc.fileName.split('.')[1]
				}
			];

			const index = 0;

			this.$previewImages({
				images: list,
				focusIndex: index,
				options: {
					presentationMode: 'doc',
					hideCommentButton: true,
					hideDisLikeButton: true,
					hideLikeDislike: true,
					hideCropButton: true,
					hideDeleteButton: true
				}
			});
		},
		async handleShowModalDocumentPackage() {
			await this.getDocumentList();
		},
		handleChangeNewDocType() {
			this.newDoc.toSign = false;
		},
		handlePreviewDocForDocumentSelected() {
			const list = [
				{
					...this.documentSelected,
					src: `${process.env.VUE_APP_GQL}/images/${this.documentSelected.xid}`,
					thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + this.documentSelected.xid,
					ext: getFileExtension(this.documentSelected.fileName)
				}
			];

			const index = 0;

			this.$previewImages({
				images: list,
				focusIndex: index,
				options: {
					presentationMode: 'doc',
					hideCommentButton: true,
					hideDisLikeButton: true,
					hideLikeDislike: true,
					hideCropButton: true,
					hideDeleteButton: true
				}
			});
		},
		handleChangeFileToUploaded(ev) {
			if (ev.type === 'drop') {
				this.eventFile = ev.dataTransfer.files[0];
			} else {
				this.eventFile = ev.target.files[0];
			}
			this.newDoc.fileName = this.eventFile.name;

			const fileReader = new FileReader();
			fileReader.addEventListener('load', () => {
				this.imageUrl = fileReader.result;
			});
			fileReader.readAsDataURL(this.eventFile);
		},
		handleChangeWatermarkToUploaded(ev) {
			if (ev.type === 'drop') {
				this.eventWatermarkFile = ev.dataTransfer.files[0];
			} else {
				this.eventWatermarkFile = ev.target.files[0];
			}
			this.newDoc.watermarkPictureFileName = this.eventWatermarkFile.name;

			const fileReader = new FileReader();
			fileReader.addEventListener('load', () => {
				this.wartermarkImageUrl = fileReader.result;
			});
			fileReader.readAsDataURL(this.eventWatermarkFile);
		},
		handleClickTrashFile() {
			this.eventFile = null;
			this.newDoc.fileName = '';
			this.$refs['file-input-doc-package'].reset();
		},
		async handleSubmit() {
			if (!this.fromDocument) {
				this.isLoading = true;

				let formData = new FormData();
				formData.append('uploadimage', this.eventFile);
				formData.append('fileName', this.eventFile.name);

				formData.append('fileType', this.eventFile.type);
				let urlStr = process.env.VUE_APP_GQL + '/upload';
				axios
					.post(urlStr, formData, {
						headers: {
							Authorization: `Bearer ${store.state.token}`,
							'Content-Type': 'multipart/form-data'
						}
					})
					.then(async (result) => {
						this.newDoc.xid = result.data;

						if (this.newDoc.useWatermark && this.eventWatermarkFile) {
							await this.uploadWatermarkDocument();
						} else {
							await this.newDocument();
							this.emitEventSuccess();

							this.$refs['file-input-doc-package'].reset();
							this.initData();
						}
					})
					.catch(() => {
						this.uploadError = true;
					})
					.finally(() => (this.isLoading = false));
			} else {
				this.newDoc = this.documentSelected;
				this.newDoc = await updateDocument(this.documentSelected.id, this.documentSelected, this.documentPackage.id);
				this.emitEventSuccess();
			}
		},
		async uploadWatermarkDocument() {
			let formData = new FormData();
			formData.append('uploadimage', this.eventWatermarkFile);
			formData.append('fileName', this.eventWatermarkFile.name);

			formData.append('fileType', this.eventWatermarkFile.type);
			formData.append('resize', '1024');
			let urlStr = process.env.VUE_APP_GQL + '/upload';
			axios
				.post(urlStr, formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(async (result) => {
					this.newDoc.watermarkPictureXid = result.data;
					await this.newDocument();
					this.emitEventSuccess();

					this.$refs['file-input-doc-package'].reset();
					this.initData();
				});
		},
		async newDocument() {
			this.newDoc = await newDocument(
				{
					name: this.newDoc.name,
					type: this.newDoc.type,
					fileName: this.newDoc.fileName,
					xid: this.newDoc.xid,
					toSign: this.newDoc.toSign,
					availableOnline: true,
					useWatermark: this.newDoc.useWatermark,
					watermarkText: this.newDoc.watermarkText,
					watermarkWithUser: this.newDoc.watermarkWithUser,
					watermarkPictureFileName: this.newDoc.watermarkPictureFileName,
					ownerPassword: this.newDoc.ownerPassword,
					userPassword: this.newDoc.userPassword,
					watermarkPictureXid: this.newDoc.watermarkPictureXid
				},
				parseInt(this.documentPackage.id)
			);
		},
		emitEventSuccess() {
			this.createToastForMobile(this.FormMSG(41, 'Success'), this.FormMSG(55, 'Document successfully added in package'), '', 'success');
			this.$emit('modal-document-package:success', {
				...this.newDoc,
				hoverAction: false
			});
		},
		initData() {
			this.fromDocument = true;
			this.typeSelected = 9;
			this.newDoc = {
				name: '',
				type: 9,
				fileName: '',
				xid: ''
			};
			this.uploadError = false;
			this.isLoading = false;
			this.fileToUploaded = null;
			this.eventFile = null;
		},
		async getDocumentList() {
			this.documentList = await getDocumentList({
				showDocumentOnBoardingPackage: false,
				removeFromListParentId: parseInt(this.documentPackage.id, 10),
				useStatus: false
			});
		},
		toggleFromDocument() {
			if (this.fromDocument) {
				this.initData();
			} else {
				this.documentSelected = null;
				this.$refs['file-input-doc-package'].reset();
			}
			this.fromDocument = !this.fromDocument;
		},
		emitEventClose() {
			this.$emit('modal-document-package:close');
		},
		getLucideIcon(name) {
			return icons[name];
		}
	}
};
</script>
